<template>
  <div class="ritual fill-height" v-if="data">
    <BackBtn class="ritual__back" />
    <div class="ritual-inner--large">
      <ArticleHeader :data="data.header" />

      <div class="ritual__content">
        <h3 class="mb-xxs">Description</h3>
        <div class="flexible">
          <div
            v-for="(cpt, index) in data.flexibleContent"
            :key="`fl-${index}`"
            :class="`fl-` + cpt.component"
          >
            <component
              v-if="cpt.component && availableFlexibles[cpt.component]"
              :is="availableFlexibles[cpt.component]"
              :data="cpt.content"
              :class="[cpt.component]"
              :key="data.header.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/rituals'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import ArticleHeader from '@/components/article/Header.vue'
import Wysiwyg from '@/components/Wysiwyg.vue'

const availableFlexibles = {
  wysiwyg: Wysiwyg,
}

export default defineComponent({
  name: 'ritual',
  components: {
    BackBtn,
    ArticleHeader,
    Wysiwyg,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      availableFlexibles,
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.ritual {
  position: relative;
  background-color: $c-white;

  ::v-deep {
    .header__title,
    .header__infos__item__label {
      .branch-horizon &,
      .branch-route & {
        color: $regal-blue;
      }
    }
  }
}

[class*='ritual-inner--'],
.ritual-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  @include mq(s) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='ritual__content--'],
.ritual__content {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}
</style>
